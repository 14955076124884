import * as React from "react"

import { ComingSoon } from "src/components/coming-soon"
import Seo from "src/components/seo"

const HomePage = () => {
  return (
    <>
      <Seo title="Home" />
      <ComingSoon />
    </>
  )
}

export default HomePage
